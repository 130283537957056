import React from 'react'
import {navigate} from '@reach/router';
import {Layout} from '../../components/layout'
import {Section, Subsection, MiddleSectionPadding} from '../../components/section';
import {langPrefix, passLanguage, useLanguage} from '../../components/language-context'
import {
  BoldText,
  CTAButton,
  OptionImage,
  Row,
  RowCol,
  Row3Col1,
  Row3Col2,
  RowGraphicWrapper,
  SectionSubheading,
  TextParagraph
} from '../../components/platform-common-components'
import {goToRequestForm} from '../../components/request-form';
import {JumboImage} from '../../components/solution-customized-components'
import digitalImage from '../../images/product-services/customizablebuttons/digital@2x.png'
import digitalRedImage from '../../images/product-services/customizablebuttons/digital-red@2x.png'
import example1Image from '../../images/product-services/customizablebuttons/example1@2x.png'
import example2Image from '../../images/product-services/customizablebuttons/example2@2x.png'
import example3Image from '../../images/product-services/customizablebuttons/example3@2x.png'
import touchPhysicalImage from '../../images/product-services/customizablebuttons/touch-physical@3x.png'

const CustomPageBase = useLanguage(({location, lang, msg}) => {
  const goToImprove = () => {
    navigate(langPrefix(lang, `/platform/insights`));
  };

  return (
    <Layout location={location}>
      <JumboImage src={touchPhysicalImage}/>
      <Section title={msg('platform-measure-custom-main-title')} lang={lang} lineWidth={100}>
        <SectionSubheading>{msg('platform-measure-custom-main-desc')}</SectionSubheading>
      </Section>
      <Subsection
        padding={MiddleSectionPadding}
        title={msg('platform-measure-custom-section-1-title')}
        lang={lang}
        headerTitleLabel={msg('platform-measure-custom-section-1-title-label')}
        showLine={true}
        lineWidth={0}
      >
        <Row>
          <TextParagraph>{msg('platform-measure-custom-section-1-text')}</TextParagraph>
        </Row>
        <Row>
          <Row3Col1>
            <RowGraphicWrapper>
              <OptionImage src={digitalImage}/>
            </RowGraphicWrapper>
          </Row3Col1>
          <Row3Col2>
            <TextParagraph>
              <BoldText>{msg('platform-measure-custom-section-1-bullet-1-heading')}</BoldText> {msg('platform-measure-custom-section-1-bullet-1-text')}
            </TextParagraph>
            <TextParagraph>
              <BoldText>{msg('platform-measure-custom-section-1-bullet-2-heading')}</BoldText> {msg('platform-measure-custom-section-1-bullet-2-text')}
            </TextParagraph>
            <TextParagraph>
              <BoldText>{msg('platform-measure-custom-section-1-bullet-3-heading')}</BoldText> {msg('platform-measure-custom-section-1-bullet-3-text')}
            </TextParagraph>
          </Row3Col2>
        </Row>
        <Row>
          <Row3Col1>
            <RowGraphicWrapper>
              <OptionImage src={example1Image}/>
            </RowGraphicWrapper>
          </Row3Col1>
          <Row3Col1>
            <RowGraphicWrapper>
              <OptionImage src={example2Image}/>
            </RowGraphicWrapper>
          </Row3Col1>
          <Row3Col1>
            <RowGraphicWrapper>
              <OptionImage src={example3Image}/>
            </RowGraphicWrapper>
          </Row3Col1>
        </Row>
        <Row>
          <TextParagraph>
            <CTAButton onClick={goToImprove}>{msg('platform-measure-custom-section-1-button-text')}</CTAButton>
          </TextParagraph>
        </Row>
      </Subsection>
      <Subsection
        padding={MiddleSectionPadding}
        title={msg('platform-measure-custom-section-2-title')} lang={lang}
        headerTitleLabel={msg('platform-measure-custom-section-2-title-label')}
        lineWidth={0}
      >
        <Row>
          <Row3Col2>
            <TextParagraph>{msg('platform-measure-custom-section-2-text')}</TextParagraph>
            <TextParagraph>
              <BoldText>{msg('platform-measure-custom-section-2-bullet-1-heading')}</BoldText> {msg('platform-measure-custom-section-2-bullet-1-text')}
            </TextParagraph>
            <TextParagraph>
              <BoldText>{msg('platform-measure-custom-section-2-bullet-2-heading')}</BoldText> {msg('platform-measure-custom-section-2-bullet-2-text')}
            </TextParagraph>
            <TextParagraph>
              <BoldText>{msg('platform-measure-custom-section-2-bullet-3-heading')}</BoldText> {msg('platform-measure-custom-section-2-bullet-3-text')}
            </TextParagraph>
          </Row3Col2>
          <Row3Col1>
            <RowGraphicWrapper>
              <OptionImage src={digitalRedImage}/>
            </RowGraphicWrapper>
          </Row3Col1>
        </Row>
      </Subsection>
      <Subsection title={msg('platform-measure-custom-section-3-title')} lang={lang} lineWidth={0}>
        <Row>
          <RowCol>
            <TextParagraph>
              <BoldText>{msg('platform-measure-custom-section-3-bullet-1-heading')}</BoldText> {msg('platform-measure-custom-section-3-bullet-1-text')}
            </TextParagraph>
            <TextParagraph>
              <BoldText>{msg('platform-measure-custom-section-3-bullet-2-heading')}</BoldText> {msg('platform-measure-custom-section-3-bullet-2-text')}
            </TextParagraph>
            <TextParagraph>
              <BoldText>{msg('platform-measure-custom-section-3-bullet-3-heading')}</BoldText> {msg('platform-measure-custom-section-3-bullet-3-text')}
            </TextParagraph>
          </RowCol>
        </Row>
        <Row>
          <TextParagraph>
            <CTAButton onClick={(event) => goToRequestForm(event, lang, location.pathname)}>
              {msg('platform-measure-custom-section-3-button-text')}
            </CTAButton>
          </TextParagraph>
        </Row>
      </Subsection>
    </Layout>
  )
})

const CustomPage = passLanguage(CustomPageBase)

export default CustomPage